<template>
  <UModal v-model="otpModelOpen" :prevent-close="true">
    <UCard>
      <template #header>
        <div class="text-xl text-center mb-2">Two Factor Authentication</div>
        <UButton
          color="gray"
          variant="ghost"
          icon="i-heroicons-x-mark-20-solid"
          class="absolute top-[10px] right-[10px]"
          @click="otpModelOpen = false"
        />
      </template>
      <div class="text-center mb-4">
        A one time password has been sent to your email address. Use it, or a
        code from an authenticator app to sign in.
      </div>
      <form @submit.prevent="otpLogin">
        <div class="flex flex-col items-center gap-4">
          <UFormGroup :error="otpError">
            <UInput
              v-model="oneTimePassword"
              type="number"
              placeholder="123456"
              inputClass="text-center font-bold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              size="xl"
            />
          </UFormGroup>
          <UButton
            @click.prevent="otpLogin"
            type="button"
            size="lg"
            :loading="loading"
          >Sign In</UButton>
          <a href="#" @click.prevent="send2faEmail">Send Code to Email</a>
          <div v-if="emailSentMessage">{{ emailSentMessage }}</div>
        </div>
      </form>
    </UCard>
  </UModal>

  <UCard>
    <template #header>
      <div class="flex flex-row justify-center">
        <UColorModeImage :light="logoLight" :dark="logoDark" class="w-1/2" />
      </div>
    </template>

    <div class="flex flex-col items-center gap-8 px-12">
      <form class="p-4 space-y-4" action="#" @submit.prevent="login">
        <UFormGroup :error="loginError.length !== 0" label="Username">
          <UInput
            v-model="username"
            id="username"
            type="text"
            size="lg"
            required
            :no-border="true"
          />
        </UFormGroup>

        <UFormGroup :error="loginError.length !== 0" label="Password">
          <UInput
            v-model="password"
            id="password"
            type="password"
            size="lg"
            required
            :no-border="true"
          />
        </UFormGroup>

        <div v-if="loginError.length > 0">
          <small class="flex justify-center text-red-500">{{
            loginError
          }}</small>
        </div>

        <div class="flex justify-end gap-4">
          <UColorModeButton button-variant="solid" />
          <UButton
            @click.prevent="login"
            color="primary"
            type="button"
            size="lg"
            :loading="loading"
            >Sign In</UButton
          >
        </div>
      </form>
    </div>
  </UCard>
</template>

<script setup>
import { ref } from "vue";
import { useUserStore } from "@/store/user";

import logoDark from "@/assets/images/logo-dark-background.svg";
import logoLight from "@/assets/images/logo-light-background.svg";

const userStore = useUserStore();

definePageMeta({
  noAuth: true,
  layout: "auth",
});

const loading = ref(false);
const username = ref("");
const password = ref("");
const loginError = ref("");

const otpModelOpen = ref(false);
const oneTimePassword = ref("");
const otpError = ref("");
const emailSentMessage = ref(null);

const login = async () => {
  loading.value = true;
  try {
    const success = await userStore.login(username.value, password.value);
    if (!success) {
      loginError.value =
        "You don't have permission to access this application.";
      return;
    }
  } catch (ex) {
    loginError.value = ex.message;
    return;
  }

  loading.value = false;
  otpModelOpen.value = true;
};

const otpLogin = async () => {
  loading.value = true;
  try {
    await userStore.auth2fa(username.value, oneTimePassword.value);
  } catch (ex) {
    console.error(ex);
    otpError.value = ex.message;
    return;
  }

  loading.value = false;
  await navigateTo("/");
};

const send2faEmail = async () => {
  if (!username.value) return;

  const { data: result } = await useGraphqlMutation("Send2faEmail", { username: username.value });
  if (result.send2faEmail.success) {
    emailSentMessage.value = "Your 2FA code was sent to your email."
  } else {
    const errs = JSON.parse(result.officeReviewTag.errors || "{}");
    emailSentMessage.value = `Failed to send email: ${errs}`
  }
}
</script>
